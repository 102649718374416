import React, { useState, useEffect } from "react";
import GuessForm from "./Form/GuessForm";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { firestore } from "../firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import Background from "./Background";
import Countdown from "./Countdown";
import ButtonMenu from "./ButtonMenu";
import Results from "./Results/Results";
import Rules from "./Rules/Rules";

// At the top of your ProtectedRoute.js file
import styles from "../styles/ProtectedRoute.module.css";
import Overview from "./Overview/Overview";

const ProtectedRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [screen, setScreen] = useState("menu");
  // eslint-disable-next-line no-unused-vars
  const [hasGuesses, setHasGuesses] = useState(false);

  useEffect(() => {
    const checkForGuesses = async () => {
      const googleId = localStorage.getItem("google_id");
      if (googleId) {
        setIsAuthenticated(true);
        // Check if the user already has guesses
        const guessesDocRef = doc(firestore, `participant-guesses/${googleId}`);
        const guessesSnap = await getDoc(guessesDocRef);
        setHasGuesses(guessesSnap.exists());
        // if (!hasGuesses) {
        //   setScreen("edit");
        // }
      }
    };
    checkForGuesses();
  }, [hasGuesses]);

  const verifyParticipant = async (googleId, name, picture) => {
    const participantRef = doc(firestore, `participants/${googleId}`);
    const participantSnap = await getDoc(participantRef);

    if (!participantSnap.exists()) {
      // if not exists in db, add them
      await setDoc(participantRef, {
        name: name,
        picture: picture,
      });
    }
  };

  const handleLoginSuccess = (credentialResponse) => {
    console.log("Login Successful", credentialResponse);
    const decoded = jwtDecode(credentialResponse.credential);
    console.log(decoded); // Just for demonstration
    const googleId = decoded.sub; // 'sub' is the Google ID

    // In a proper application, send `credential` to a backend server to verify before trusting
    localStorage.setItem("google_id", googleId);
    setIsAuthenticated(true);

    verifyParticipant(googleId, decoded.name, decoded.picture);
  };

  const handleLoginFailure = () => {
    console.error("Login Failed");
  };

  const handleLogout = () => {
    googleLogout();
    localStorage.removeItem("google_id");
    setIsAuthenticated(false);
    setScreen("menu");
  };

  if (!isAuthenticated) {
    return (
      <>
        <Background />
        <div className={styles.loginContainer}>
          <GoogleLogin
            onSuccess={handleLoginSuccess}
            onError={handleLoginFailure}
          />
        </div>
      </>
    );
  } else if (screen === "edit") {
    return (
      <>
        <Background />
        <GuessForm screenSelected={setScreen} />
      </>
    );
  } else if (screen === "overview") {
    return (
      <>
        <Background />
        <Overview screenSelected={setScreen} />
      </>
    );
  } else if (screen === "rules") {
    return (
      <>
        <Background />
        <Rules screenSelected={setScreen} />
      </>
    );
  } else if (screen === "submit") {
    return (
      <>
        <Background />
        <Results screenSelected={setScreen} />
      </>
    );
  }

  return (
    <>
      <Background />
      <div className={styles.main}>
        <Countdown />
        <ButtonMenu screenSelected={setScreen} logout={handleLogout} />
        {/* <OutcomesTable /> */}
      </div>
    </>
  );
};

export default ProtectedRoute;
