// ListWinners.js
import React, { useEffect, useState, useCallback } from "react";
import { firestore } from "../../firebase"; // Assumed import of your firestore setup
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
  Stack,
} from "@mui/material";
import {
  updateDoc,
  collection,
  query,
  orderBy,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import styles from "../../styles/Results/ListWinners.module.css";

const ListWinners = ({
  shouldReload,
  isAdmin,
  reloadCallback,
  setShouldReload,
}) => {
  const [winners, setWinners] = useState([]);

  const fetchCategoryName = async (categoryId) => {
    const categoryRef = doc(firestore, "categories", categoryId);
    const categorySnap = await getDoc(categoryRef);
    return categorySnap.exists() ? categorySnap.data().name : null;
  };

  const fetchGameTitle = async (gameId) => {
    const gameRef = doc(firestore, "games", gameId);
    const gameSnap = await getDoc(gameRef);
    return gameSnap.exists() ? gameSnap.data().title : null;
  };

  const fetchWinners = useCallback(async () => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(firestore, "category-winners"),
          orderBy("announcementOrder")
        )
      );
      const winnersDataPromises = querySnapshot.docs.map(
        async (docSnapshot) => {
          const data = docSnapshot.data();
          const categoryName = await fetchCategoryName(data.categoryId);
          const gameTitle = await fetchGameTitle(data.gameId);
          return {
            id: docSnapshot.id,
            announcementOrder: data.announcementOrder,
            categoryId: data.categoryId,
            categoryName,
            gameId: data.gameId,
            gameTitle,
          };
        }
      );

      // Resolve all promises from fetching the additional data
      const winnersData = await Promise.all(winnersDataPromises);
      console.log("Winners fetched:", winnersData); // Debugging line
      setWinners(winnersData);
    } catch (error) {
      console.error("Error fetching winners:", error);
    }
  }, []);

  useEffect(() => {
    fetchWinners();
    setShouldReload(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldReload]);

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(winners);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update state
    setWinners(items);

    // Update database
    for (let i = 0; i < items.length; i++) {
      const winnerRef = doc(firestore, "category-winners", items[i].id);
      await updateDoc(winnerRef, {
        announcementOrder: i + 1, // Updating to the new order
      });
    }
  };

  const onDeleteWinner = async (winnerId, index) => {
    // Remove from Firestore
    const winnerRef = doc(firestore, "category-winners", winnerId);
    await deleteDoc(winnerRef);

    // Update local state by removing the item from the list
    const newWinners = winners.filter((winner) => winner.id !== winnerId);

    // Update the announcement order in local state
    const updatedWinners = newWinners.map((winner, i) => ({
      ...winner,
      announcementOrder: i + 1,
    }));
    setWinners(updatedWinners);

    // Batch update the announcement order in the database
    for (let i = index; i < updatedWinners.length; i++) {
      const winnerUpdateRef = doc(
        firestore,
        "category-winners",
        updatedWinners[i].id
      );
      await updateDoc(winnerUpdateRef, {
        announcementOrder: i + 1,
      });
    }

    // Callback to reload the winners list
    reloadCallback();
  };

  return (
    <>
      <div className={styles.header}>
        <h1>Winners</h1>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="winners" isDropDisabled={!isAdmin}>
            {(provided) => (
              <List {...provided.droppableProps} ref={provided.innerRef}>
                <Stack spacing={2}>
                  {winners.map((winner, index) => (
                    <Draggable
                      key={winner.id}
                      draggableId={winner.id}
                      index={index}
                    >
                      {(provided) => (
                        <Paper
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          elevation={2}
                        >
                          <ListItem
                            secondaryAction={
                              isAdmin && (
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() =>
                                    onDeleteWinner(winner.id, index)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )
                            }
                          >
                            <ListItemIcon>
                              <div className={styles.announcementOrder}>
                                {winner.announcementOrder}
                              </div>
                            </ListItemIcon>
                            <ListItemText
                              primary={winner.categoryName}
                              secondary={`Winner: ${winner.gameTitle}`}
                            />
                          </ListItem>
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                </Stack>
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};

export default ListWinners;
