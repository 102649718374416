import React from "react";
import styles from "../../styles/CreditOverlay.module.css";
import { useMemo } from "react";
import Gold from "../../images/gold.png";
import Silver from "../../images/silver.png";
import Bronze from "../../images/bronze.png";
import Tiebreaker from "../../images/tiebreaker.png";

const CreditsOverlay = ({
  winners,
  stats,
  leaderboard,
  participants,
  onClose,
}) => {
  const participantsLookup = useMemo(() => {
    const lookup = {};
    participants.forEach((participant) => {
      lookup[participant.id] = participant;
    });
    return lookup;
  }, [participants]);

  const getPlacementImage = (index) => {
    switch (index) {
      case 0:
        return Gold;
      case 1:
        return Silver;
      case 2:
        return Bronze;
      default:
        return null;
    }
  };

  const calculateMostCorrectGuessesInARow = (participants, winners) => {
    let maxStreak = 0;
    let bestParticipant = null;

    participants.forEach((participant) => {
      let currentStreak = 0;
      let longestStreak = 0;

      winners.forEach((winner) => {
        if (participant.guesses[winner.categoryId] === winner.gameId) {
          currentStreak++;
          if (currentStreak > longestStreak) {
            longestStreak = currentStreak;
          }
        } else {
          currentStreak = 0;
        }
      });

      if (longestStreak > maxStreak) {
        maxStreak = longestStreak;
        bestParticipant = participant.id;
      }
    });

    return { bestParticipant, maxStreak };
  };

  const findGuessTimes = () => {
    let longestGuessTime = 0;
    let shortestGuessTime = Infinity;
    let participantWithLongestGuessTime = null;
    let participantWithShortestGuessTime = null;

    for (const [id, stat] of Object.entries(stats)) {
      if (stat.guessTime > longestGuessTime) {
        longestGuessTime = stat.guessTime;
        participantWithLongestGuessTime = id;
      }
      if (stat.guessTime < shortestGuessTime) {
        shortestGuessTime = stat.guessTime;
        participantWithShortestGuessTime = id;
      }
    }

    return {
      participantWithLongestGuessTime,
      longestGuessTime,
      participantWithShortestGuessTime,
      shortestGuessTime,
    };
  };

  const findLastToGuess = () => {
    let closestToDueDate = null;
    let participantClosestToDueDate = null;
    let timeDifference = null;

    const dueDate = new Date("2024-12-12T22:00:00Z");

    for (const [id, stat] of Object.entries(stats)) {
      const guessDate = new Date(stat.lastExitTime);

      const diffInMs = Math.abs(guessDate - dueDate);
      if (closestToDueDate === null || diffInMs < closestToDueDate) {
        closestToDueDate = diffInMs;
        participantClosestToDueDate = id;

        const diffInSeconds = Math.floor(diffInMs / 1000);
        const hours = Math.floor(diffInSeconds / 3600);
        const minutes = Math.floor((diffInSeconds % 3600) / 60);
        const seconds = diffInSeconds % 60;

        timeDifference = { hours, minutes, seconds };
      }
    }

    return { participantClosestToDueDate, closestToDueDate, timeDifference };
  };

  const findMostRefreshes = () => {
    let maxRefreshes = 0;
    let participantWithMostRefreshes = null;

    for (const [id, stat] of Object.entries(stats)) {
      if (stat.reloadCount > maxRefreshes) {
        maxRefreshes = stat.reloadCount;
        participantWithMostRefreshes = id;
      }
    }

    return { participantWithMostRefreshes, maxRefreshes };
  };

  const { participantWithMostRefreshes, maxRefreshes } = findMostRefreshes();
  const {
    participantWithLongestGuessTime,
    longestGuessTime,
    participantWithShortestGuessTime,
    shortestGuessTime,
  } = findGuessTimes();

  return (
    <div className={styles.overlay}>
      <div className={styles.content}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <ul className={styles.winnersList}>
          <h2>Game Awards 2024</h2>
          {leaderboard.slice(0, 3).map((leaderboard, index) => (
            <li key={index} className={styles.winnerItem}>
              <img
                className={styles.placementImage}
                src={getPlacementImage(index)}
                alt={`${index + 1} place`}
              />
              <div>
                {participantsLookup[leaderboard.participantId]?.name} -{" "}
                {leaderboard.score} points
                <div className={styles.tiebreakerImages}>
                  {leaderboard.tiebreakers > 0 &&
                    Array.from({ length: leaderboard.tiebreakers }).map(
                      (_, index) => (
                        <img
                          key={index}
                          className={styles.tiebreakerImage}
                          src={Tiebreaker}
                          alt="Tiebreaker"
                        />
                      )
                    )}
                </div>
              </div>
            </li>
          ))}

          <h2>Achievements</h2>
          <div className={styles.achievement}>
            <h3>Did I win yet??</h3>
            {participantWithMostRefreshes && (
              <div>
                <p className={styles.achievementDetails}>
                  Refreshed the table {maxRefreshes} times
                </p>
                <p className={styles.achievementName}>
                  {participantsLookup[participantWithMostRefreshes]?.name}
                </p>
              </div>
            )}
          </div>
          <div className={styles.achievement}>
            <h3>What is taking so long?</h3>
            {participantWithLongestGuessTime && (
              <div>
                <p className={styles.achievementDetails}>
                  Took the longest time to guess: {formatTime(longestGuessTime)}
                </p>
                <p className={styles.achievementName}>
                  {participantsLookup[participantWithLongestGuessTime]?.name}
                </p>
              </div>
            )}
          </div>
          <div className={styles.achievement}>
            <h3>Whatever, I am sure it is fine.</h3>
            {participantWithShortestGuessTime && (
              <div>
                <p className={styles.achievementDetails}>
                  Took the shortest time to guess:{" "}
                  {formatTime(shortestGuessTime)}
                </p>
                <p className={styles.achievementName}>
                  {participantsLookup[participantWithShortestGuessTime]?.name}
                </p>
              </div>
            )}
          </div>
          <div className={styles.achievement}>
            <h3>Late to the party</h3>
            {(() => {
              const lastToGuess = findLastToGuess();
              return (
                lastToGuess.participantClosestToDueDate && (
                  <div>
                    <p className={styles.achievementDetails}>
                      Last to guess, {lastToGuess.timeDifference.hours} hours,{" "}
                      {lastToGuess.timeDifference.minutes} minutes,{" "}
                      {lastToGuess.timeDifference.seconds} seconds left
                    </p>
                    <p className={styles.achievementName}>
                      {
                        participantsLookup[
                          lastToGuess.participantClosestToDueDate
                        ]?.name
                      }
                    </p>
                  </div>
                )
              );
            })()}
          </div>
          <div className={styles.achievement}>
            <h3>Streaker</h3>
            {(() => {
              const mostCorrectGuessesInARow =
                calculateMostCorrectGuessesInARow(participants, winners);
              return (
                mostCorrectGuessesInARow && (
                  <div>
                    <p className={styles.achievementDetails}>
                      Most correct guesses in a row:{" "}
                      {mostCorrectGuessesInARow.maxStreak}
                    </p>
                    <p className={styles.achievementName}>
                      {
                        participantsLookup[
                          mostCorrectGuessesInARow.bestParticipant
                        ]?.name
                      }
                    </p>
                  </div>
                )
              );
            })()}
          </div>
        </ul>
      </div>
    </div>
  );
};

// Helper function to format time
function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.round(seconds % 60);
  return `${minutes} minutes and ${remainingSeconds} seconds`;
}

export default CreditsOverlay;
