import React from "react";
import ProtectedRoute from "./components/ProtectedRoute"; // New ProtectedRoute component
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <GoogleOAuthProvider clientId="370519918687-66gk1alig6ldd2l8pur2vkh924ig3l84.apps.googleusercontent.com">
        <div className="App">
          <ProtectedRoute />
        </div>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;
