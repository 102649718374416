import React from "react";
import OutcomesTable from "./OutcomesTable";
import OutcomesToggleMenu from "./OutcomesToggleMenu";
import styles from "../../styles/Table/Table.module.css";
import { Button } from "@mui/material";
import { faRotateBack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Table = ({
  screenSelected,
  categories,
  guesses,
  games,
  participants,
  categoryWinners,
  toggleParticipantVisibility,
  visibleParticipants,
  loading,
  reloadCallback,
}) => {
  const filterParticipants = participants.filter(
    (participant) => visibleParticipants[participant.id]
  );

  return (
    <div className={styles.tableContainer}>
      <OutcomesToggleMenu
        participants={participants}
        visibleParticipants={visibleParticipants}
        toggleParticipantVisibility={toggleParticipantVisibility}
      />
      <Button className={styles.reloadButton} onClick={reloadCallback}>
        <FontAwesomeIcon icon={faRotateBack} />
        &nbsp;{" Reload"}{" "}
      </Button>
      <OutcomesTable
        screenSelected={screenSelected}
        participants={filterParticipants}
        categories={categories}
        games={games}
        winners={categoryWinners}
        loading={loading}
      />
    </div>
  );
};

export default Table;
