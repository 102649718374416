import { firestore } from "../firebase"; // Import your firebase config correctly
import {
  collection,
  getDocs,
  query,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";

const fetchNominationsForCategory = async (categoryId) => {
  const gamesQuery = query(
    collection(firestore, `categories/${categoryId}/games`)
  );
  const gamesSnapshot = await getDocs(gamesQuery);
  const games = gamesSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  // Sort the games if needed, or manipulate as necessary
  return games;
};

const sortCategories = (categories) => {
  // Split categories into normal categories and tiebreaker categories
  const normalCategories = categories.filter(
    (category) => !category.isTiebreaker && category.id !== "game-of-the-year"
  );
  const tiebreakerCategories = categories.filter(
    (category) => category.isTiebreaker
  );
  const gotyCategory = categories.find(
    (category) => category.id === "game-of-the-year"
  );

  // Return an object with all categories separated
  return {
    normalCategories: normalCategories,
    tiebreakerCategories: tiebreakerCategories,
    gotyCategory: gotyCategory, // This may be undefined if not found
  };
};

export { fetchNominationsForCategory, sortCategories };
