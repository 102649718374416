import React, { useEffect, useState } from "react";
import { firestore } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  orderBy,
  getDoc,
  setDoc,
  doc,
} from "firebase/firestore";
import { Tabs, Tab } from "@mui/material";

import Leaderboard from "./Leaderboard";
import Table from "../Table/Table";
import Results from "../Results/Results";
import getGoogleId, { sortCategories } from "../../utils";
import TGALogo from "../../images/logo-tga.svg";

import styles from "../../styles/Overview.module.css";

const Overview = ({ screenSelected }) => {
  const [participants, setParticipants] = useState([]);
  const [categories, setCategories] = useState([]); // [ { id, name, games: [ { id, title } ] }
  const [games, setGames] = useState([]); // { id: title }
  const [visibleParticipants, setVisibleParticipants] = useState({});
  const [guesses, setGuesses] = useState({});
  const [stats, setStats] = useState({});
  const [winners, setWinners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [shouldReload, setShouldReload] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true while fetching

      const [
        adminSnapshot,
        categoriesSnapshot,
        gamesSnapshot,
        guessesSnapshot,
        statsSnapshot,
      ] = await Promise.all([
        getDocs(collection(firestore, "admin")),
        getDocs(collection(firestore, "categories")),
        getDocs(collection(firestore, "games")),
        getDocs(collection(firestore, "participant-guesses")),
        getDocs(collection(firestore, "stats")), // Changed from getDoc to getDocs
      ]);

      const userGoogleId = getGoogleId();
      const adminDocs = adminSnapshot.docs.map((doc) => doc.id);

      let isAdminLocal = false;
      if (adminDocs.includes(userGoogleId)) {
        isAdminLocal = true;
        setIsAdmin(true);
      }

      const specificDatetime = new Date("2024-12-12T19:00:00-05:00"); // Replace with your specific datetime
      const currentDatetime = new Date();
      const fetchedParticipants = [];
      if (currentDatetime > specificDatetime || isAdminLocal) {
        for (const guessDoc of guessesSnapshot.docs) {
          const participantId = guessDoc.id;
          const guesses = guessDoc.data();
          // Replace the game IDs in guesses with the corresponding game titles
          const guessTitles = {};
          Object.keys(guesses).forEach((categoryId) => {
            guessTitles[categoryId] =
              games[guesses[categoryId]] || guesses[categoryId];
          });
          // Fetch participant details
          const participantRef = doc(
            firestore,
            `participants/${participantId}`
          );
          const participantSnap = await getDoc(participantRef);
          if (participantSnap.exists()) {
            const { name, picture } = participantSnap.data();
            fetchedParticipants.push({
              id: participantId,
              name,
              picture,
              guesses, // Replace IDs with titles in guesses
              // guesses: guessTitles, // Replace IDs with titles in guesses
            });
          }
        }
        setParticipants(fetchedParticipants);
      } else {
        // If the current datetime is before the specific datetime, only show the current user's guesses
        const participantRef = doc(firestore, `participants/${userGoogleId}`);
        const participantSnap = await getDoc(participantRef);
        if (participantSnap.exists()) {
          const { name, picture } = participantSnap.data();
          const guesses = guessesSnapshot.docs
            .find((doc) => doc.id === userGoogleId)
            .data();
          // Replace the game IDs in guesses with the corresponding game titles
          const guessTitles = {};
          Object.keys(guesses).forEach((categoryId) => {
            guessTitles[categoryId] =
              games[guesses[categoryId]] || guesses[categoryId];
          });
          fetchedParticipants.push({
            id: userGoogleId,
            name,
            picture,
            guesses, // Replace IDs with titles in guesses
          });
        }
        setParticipants(fetchedParticipants);
      }

      // Set the initial visibility of participants to just the the current user
      // Use the user's Google ID to match with the participant IDs
      const participantVisibility = {};
      fetchedParticipants.forEach((participant) => {
        participantVisibility[participant.id] = false;
      });
      participantVisibility[getGoogleId()] = true;

      setVisibleParticipants(participantVisibility);

      // Process categories
      const fetchedCategories = [];
      categoriesSnapshot.forEach((doc) => {
        fetchedCategories.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setCategories(sortCategories(fetchedCategories));

      // Process games
      const fetchedGames = {};
      gamesSnapshot.forEach((doc) => {
        fetchedGames[doc.id] = {
          id: doc.id,
          ...doc.data(),
        };
      });

      const gamesArray = Object.values(fetchedGames);

      setGames(gamesArray);
      // Process guesses
      const fetchedGuesses = {};
      guessesSnapshot.forEach((doc) => {
        fetchedGuesses[doc.id] = doc.data();
      });
      setGuesses(fetchedGuesses);

      const statsData = {};
      statsSnapshot.forEach((doc) => {
        statsData[doc.id] = doc.data();
      });
      setStats(statsData);

      setLoading(false); // Set loading to false after fetching
    };

    fetchData().catch(console.error);

    const fetchWinners = async () => {
      const winnersQuerySnapshot = await getDocs(
        query(
          collection(firestore, "category-winners"),
          orderBy("announcementOrder")
        )
      );
      const fetchedWinners = winnersQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setWinners(fetchedWinners);
    };

    fetchWinners().catch(console.error);

    if (shouldReload) {
      fetchWinners().catch(console.error);
      setShouldReload(false); // Reset shouldReload after fetching
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldReload]); // Only run this effect if shouldReload changes

  const toggleParticipantVisibility = (participantId) => {
    setVisibleParticipants((prevState) => ({
      ...prevState,
      [participantId]: !prevState[participantId],
    }));
  };

  const handleTabChange = (event, newValue) => {
    setPage(newValue);
    //setShouldReload(true); // Trigger data reloading when changing tabs
  };

  const reloadCallback = async () => {
    setShouldReload(true);

    const googleId = localStorage.getItem("google_id");
    const statsRef = doc(firestore, `stats/${googleId}`);
    const statsDoc = await getDoc(statsRef);

    let reloadCount = 0;
    if (statsDoc.exists()) {
      reloadCount = statsDoc.data().reloadCount || 0;
    }

    reloadCount += 1;
    await setDoc(statsRef, { reloadCount }, { merge: true });
  };

  const disableReload = () => {
    setShouldReload(false);
  };

  return (
    <div>
      <div className="tabHeader">
        <img
          src={TGALogo}
          alt="The Game Awards"
          className={styles.logo}
          onClick={() => {
            console.log("Logo clicked");
            screenSelected("menu");
          }}
        />

        <Tabs
          value={page}
          onChange={handleTabChange}
          centered
          TabIndicatorProps={{ style: { backgroundColor: "#ffb300" } }}
        >
          <Tab
            label="Leaderboard"
            sx={{ color: "white", "&.Mui-selected": { color: "#ffb300" } }}
          />
          <Tab
            label="Table"
            sx={{ color: "white", "&.Mui-selected": { color: "#ffb300" } }}
          />
          <Tab
            label="Announced"
            sx={{ color: "white", "&.Mui-selected": { color: "#ffb300" } }}
          />
        </Tabs>
      </div>
      {page === 0 && (
        <Leaderboard
          participants={participants}
          guesses={guesses}
          stats={stats}
          winners={winners}
          loading={loading}
          shouldReload={shouldReload}
          disableReload={disableReload}
        />
      )}
      {page === 1 && (
        <Table
          participants={participants}
          guesses={guesses}
          games={games}
          categories={categories}
          loading={loading}
          visibleParticipants={visibleParticipants}
          screenSelected={screenSelected}
          categoryWinners={winners}
          toggleParticipantVisibility={toggleParticipantVisibility}
          reloadCallback={reloadCallback}
        />
      )}
      {page === 2 && (
        <Results
          shouldReload={shouldReload}
          reloadCallback={reloadCallback}
          isAdmin={isAdmin}
          setShouldReload={setShouldReload}
        />
      )}
    </div>
  );
};

export default Overview;
