import { Tooltip } from "react-tooltip";
import styles from "../../styles/Tooltip/GameTooltip.module.css";
import { useEffect } from "react";

const GameTooltip = ({ gameInfo, allCategories, nominations, isCategory }) => {
  useEffect(() => {}, [gameInfo, allCategories, nominations, isCategory]);
  return (
    <>
      {isCategory ? (
        <Tooltip
          anchorSelect=".category"
          place="bottom"
          className="tooltip-content"
          z-index="0"
        >
          <p>Nominations</p>
          <ul>
            {nominations.map((game) => (
              <li key={game.id}>{game.title}</li>
            ))}
          </ul>
        </Tooltip>
      ) : (
        <Tooltip
          anchorSelect=".game-info"
          place="bottom"
          className="tooltip-content"
          openOnClick
          closeEvents="mouseleave"
          clickable
        >
          <iframe
            src={gameInfo.video}
            frameBorder="0"
            scrolling="no"
            allow="autoplay; fullscreen"
            title="Game Video"
          ></iframe>

          <div className={styles.tooltipContent}>
            <div className={styles.tooltipNomination}>
              <p>Categories</p>
              <ul>
                {gameInfo &&
                  gameInfo.nominationCategories.map((categoryId) => (
                    <li key={categoryId}>
                      {allCategories.find((cat) => cat.id === categoryId)
                        ? allCategories.find((cat) => cat.id === categoryId)
                            .name
                        : "Unknown Category"}
                    </li>
                  ))}
              </ul>
            </div>
            <div className={styles.tooltipMetacritic}>
              <p>Metascore</p>
              {gameInfo && (
                <>
                  <p
                    className={`${styles.scoreBox} ${
                      gameInfo.score >= 75
                        ? styles.green
                        : gameInfo.score >= 50
                        ? styles.yellow
                        : styles.red
                    }`}
                  >
                    {gameInfo.score}
                  </p>
                  <div className={styles.metacriticLink}>
                    <a
                      href={gameInfo.metacritic}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Metacritic
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default GameTooltip;
