// OutcomesToggleMenu.jsx
import React from "react";
import { Box, List, ListItem, Switch } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

import styles from "../../styles/Table/OutcomesToggleMenu.module.css";

const OutcomesToggleMenu = ({
  participants,
  visibleParticipants,
  toggleParticipantVisibility,
}) => {
  return (
    <Box sx={{ minWidth: 240 }} className={styles.toggleMenu}>
      <List
        dense
        sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
      >
        {participants.map((participant) => (
          <React.Fragment key={participant.id}>
            <ListItem
              sx={{ width: "auto" }}
              secondaryAction={
                <Switch
                  edge="end"
                  checked={visibleParticipants[participant.id]}
                  onChange={() => toggleParticipantVisibility(participant.id)}
                />
              }
            >
              <ListItemAvatar>
                <Avatar src={participant.picture} alt={participant.name} />
              </ListItemAvatar>
              <ListItemText
                primary={participant.name}
                style={{ paddingRight: "10px" }}
              />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default OutcomesToggleMenu;
