// Background.js
import React from "react";
import "../styles/Background.css"; // Import the CSS file for styling
import backgroundImage from "../images/TGA24_KV_01.png"; // Import the background image

const Background = () => {
  return (
    <div
      className="backgroundContainer"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 30%, #e85709 100%), url(${backgroundImage})`,
      }}
    />
  );
};

export default Background;
