import React, { useState, useEffect } from "react";
import styles from "../../styles/Table/OutcomesTable.module.css";
import tableDragger from "table-dragger";
import { fetchNominationsForCategory } from "../../utils/database";
import { Tooltip } from "react-tooltip";

import classNames from "classnames";
import GameTooltip from "../Tooltip/GameTooltip";

const OutcomesTable = ({
  screenSelected,
  categories,
  participants,
  loading,
  winners,
  games,
}) => {
  const [nominations, setNominations] = useState([]);
  const [gameInfo, setGameInfo] = useState({
    video: "",
    nominationCategories: [""],
    nominationCount: 0,
    score: "",
    metacritic: "",
  });

  const [isCategory, setIsCategory] = useState(true);
  const [tooltip, showTooltip] = useState(false);

  // Define the tiebreaker categories
  const tiebreakerCategories = [
    "adaptation",
    "content-creator-of-the-year",
    "most-anticipated-game",
    "mobile-game",
    "esports-event",
    "esports-game",
  ];

  useEffect(() => {
    if (!loading && categories.length > 0 && participants.length > 0) {
      // Only initialize tableDragger once the component has all needed data and rendered the table
      const el = document.getElementById("results");
      if (el) {
        const dragger = tableDragger(el, {
          mode: "column", // Drag columns
          onlyBody: false, // Include the header
          animation: 300,
          // omit dragHandler if the whole header cell should be draggable
        });

        dragger.on("drop", function (from, to) {
          console.log(`from: ${from} to: ${to}`);
          // Add logic to handle column arrangement changes here if necessary
        });

        // Clean up if needed when component unmounts or data changes
        return () => {
          // You would add cleanup logic here if the tableDragger library supports it
        };
      }
    }
  }, [loading, categories, games, participants]);

  const fetchNominations = async (categoryId) => {
    const nominations = await fetchNominationsForCategory(categoryId);
    setNominations(nominations);
  };

  // Helper function to determine if a guess is correct
  const isWinner = (categoryId, gameTitleGuess) => {
    const winner = winners.find((w) => w.categoryId === categoryId);
    return winner && winner.gameId === gameTitleGuess;
  };

  // Helper function to determine if a category has been called (announced)
  const isCalled = (categoryId) => {
    const winner = winners.find((w) => w.categoryId === categoryId);
    return winner && winner.announcementOrder != null;
  };

  const isTiebreaker = (categoryId) => {
    return tiebreakerCategories.includes(categoryId);
  };

  const regularCategories = categories.filter(
    (category) => !tiebreakerCategories.includes(category.id)
  );
  const tiebreakers = categories.filter((category) =>
    tiebreakerCategories.includes(category.id)
  );

  const handleGameClick = (gameId) => {
    setIsCategory(false);
    console.log(tooltip);
    showTooltip(true);
    if (gameId) {
      const game = games.find((game) => game.id === gameId);
      setGameInfo({
        video: game.video,
        nominationCategories: game.nominationCategories,
        nominationCount: game.nominationCount,
        score: game.score,
        metacritic: game.metacritic,
      });
    }
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <img
          src="https://cdn.thegameawards.com/frontend/gifs/TGA24_LOGO_LOADER.gif"
          alt="TGA-Logo"
          height="140"
          width="140"
        />
      </div>
    );
  }

  return (
    <>
      {/* <div className={styles.returnButton}>
        <Button
          variant="contained"
          className={styles.button}
          onClick={() => screenSelected("menu")}
        >
          <ArrowBack />
        </Button>
      </div> */}
      <table id="results" className={styles.outcomesTable}>
        <thead>
          <tr>
            <th>Category</th>
            {participants.map((participant) => (
              <th key={participant.id} className="handle">
                <div className={styles.participant}>
                  <img
                    className={styles.participantImage}
                    src={participant.picture}
                    alt={participant.name}
                  />
                  <div className={styles.participantName}>
                    {participant.name}
                  </div>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {regularCategories.map((category) => (
            <tr
              key={category.id}
              className={classNames(styles.category, {
                [styles.goldCategory]: category.id === "game-of-the-year",
                [styles.categoryCalled]: isCalled(category.id),
                [styles.tiebreaker]: isTiebreaker(category.id), // Add this line for the tiebreaker style
              })}
              onMouseEnter={() => fetchNominations(category.id)}
            >
              <td
                className="category"
                onMouseEnter={() => setIsCategory(true)}
                onMouseLeave={() => setIsCategory(false)}
              >
                {category.name}
              </td>
              {participants.map((participant) => {
                const gameTitleGuess =
                  games.find(
                    (game) => game.id === participant.guesses[category.id]
                  )?.title || "-";
                return (
                  <td
                    key={`${category.id}-${participant.id}`}
                    className={classNames({
                      "game-info": true,
                      [styles.goldCategory]: category.id === "game-of-the-year",
                      [styles.correctGuess]: isWinner(
                        category.id,
                        participant.guesses[category.id]
                      ),
                    })}
                    onClick={(e) =>
                      handleGameClick(participant.guesses[category.id])
                    }
                  >
                    {gameTitleGuess}
                  </td>
                );
              })}
            </tr>
          ))}
          {tiebreakers.map((category) => (
            <tr
              key={category.id}
              className={classNames(styles.category, {
                [styles.goldCategory]: category.id === "game-of-the-year",
                [styles.categoryCalled]: isCalled(category.id),
                [styles.tiebreaker]: isTiebreaker(category.id), // Add this line for the tiebreaker style
              })}
            >
              <td className="category">{category.name}</td>
              {participants.map((participant) => {
                const gameTitleGuess =
                  games.find(
                    (game) => game.id === participant.guesses[category.id]
                  )?.title || "-";
                return (
                  <td
                    key={`${category.id}-${participant.id} game-info`}
                    className={classNames({
                      "game-info": true,
                      [styles.goldCategory]: category.id === "game-of-the-year",
                      [styles.correctGuess]: isWinner(
                        category.id,
                        participant.guesses[category.id]
                      ),
                    })}
                    onClick={(e) =>
                      handleGameClick(participant.guesses[category.id])
                    }
                  >
                    {gameTitleGuess}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      {/* <Tooltip
        anchorSelect=".category"
        place="bottom"
        className="tooltip-content"
      >
        <p>Nominations</p>
        <ul>
          {nominations.map((game) => (
            <li key={game.id}>{game.title}</li>
          ))}
        </ul>
      </Tooltip> */}
      {tooltip && (
        <GameTooltip
          gameInfo={gameInfo}
          allCategories={categories}
          nominations={nominations}
          isCategory={isCategory}
        />
      )}
    </>
  );
};

export default OutcomesTable;
