import React from "react";
import styles from "../styles/ButtonMenu.module.css";
import { Button } from "@mui/material";

const ButtonMenu = ({ screenSelected, logout }) => {
  const isDisabled = new Date() > new Date("2024-12-12T19:00:00-05:00");
  return (
    <div className={styles.buttonMenu}>
      <Button
        variant="contained"
        className={styles.button}
        onClick={() => screenSelected("edit")}
        disabled={isDisabled}
      >
        Guesses
      </Button>
      <Button
        variant="contained"
        className={styles.button}
        onClick={() => screenSelected("overview")}
      >
        Overview
      </Button>
      <Button
        variant="contained"
        className={styles.button}
        onClick={() => screenSelected("rules")}
      >
        Rules
      </Button>
      <Button
        variant="outlined"
        className={styles.logoutButton}
        onClick={logout}
      >
        Logout
      </Button>
    </div>
  );
};

export default ButtonMenu;
