import React, { useState, useEffect } from "react";
import { firestore } from "../../firebase"; // Import your firebase config correctly
import { collection, getDocs, doc, setDoc, getDoc } from "firebase/firestore";
import styles from "../../styles/Form/GuessForm.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getGoogleId from "../../utils";
import { Button, IconButton } from "@mui/material";
import { ArrowBack, Info } from "@mui/icons-material";
import {
  fetchNominationsForCategory,
  sortCategories,
} from "../../utils/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import GameTooltip from "../Tooltip/GameTooltip";

const GuessForm = ({ screenSelected }) => {
  const [categories, setCategories] = useState([]);
  const [games, setGames] = useState([]);
  const [guesses, setGuesses] = useState({});
  const [loading, setLoading] = useState(true); // Track loading state
  const [, setMadeEdits] = useState(false); // Track if user has made edits
  const [gameInfo, setGameInfo] = useState({
    video: "",
    nominationCategories: [""],
    score: "",
    metacritic: "",
  });
  useEffect(() => {
    const entryTime = Date.now();
    const googleId = localStorage.getItem("google_id");

    return async () => {
      const exitTime = Date.now();
      const timeSpent = (exitTime - entryTime) / 1000; // time in seconds

      const statsRef = doc(firestore, `stats/${googleId}`);
      const statsDoc = await getDoc(statsRef);

      let existingTime = 0;
      if (statsDoc.exists()) {
        existingTime = statsDoc.data().guessTime || 0;
      }

      const newTime = existingTime + timeSpent;
      await setDoc(
        statsRef,
        {
          guessTime: newTime,
          lastExitTime: new Date(exitTime).toISOString(),
        },
        { merge: true }
      );
    };
  }, []);

  const fetchCategoriesAndNominations = async () => {
    try {
      const categoriesCol = collection(firestore, "categories");
      const categoriesSnapshot = await getDocs(categoriesCol);
      const categoriesData = categoriesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Fetch nominations (games) for each category
      for (let category of categoriesData) {
        category.nominations = await fetchNominationsForCategory(category.id);
      }

      setCategories(sortCategories(categoriesData));

      // Initializes guesses object with all categories
      const initialGuesses = categoriesData.reduce((acc, category) => {
        acc[category.id] = "";
        return acc;
      }, {});
      setGuesses(initialGuesses);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories and games:", error);
      setLoading(false);
    }
  };

  const fetchGames = async () => {
    try {
      const gamesCol = collection(firestore, "games");
      const gamesSnapshot = await getDocs(gamesCol);
      const gamesData = gamesSnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data();
        return acc;
      }, {});

      setGames(gamesData);
    } catch (error) {
      console.error("Error fetching games:", error);
    }
  };

  const fetchGuesses = async (participantId) => {
    try {
      const guessesDocRef = doc(
        firestore,
        `participant-guesses/${participantId}`
      );
      const guessesSnapshot = await getDoc(guessesDocRef);

      if (guessesSnapshot.exists()) {
        setGuesses(guessesSnapshot.data());
      } else {
        console.log("No previous guesses found");
      }
    } catch (error) {
      console.error("Error fetching participant's guesses:", error);
    }
  };

  const saveGuesses = async (participantId, guesses) => {
    try {
      const guessesColRef = doc(
        firestore,
        `participant-guesses/${participantId}`
      );
      await setDoc(guessesColRef, guesses);
      toast.success("Answers saved!");
    } catch (error) {
      console.error("Error saving guesses:", error);
      toast.error("Failed to save answers. Please try again.");
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        await fetchCategoriesAndNominations(); // This must finish first
        const googleId = getGoogleId(); // Then get the Google ID
        await fetchGuesses(googleId); // Then fetch guesses for this ID
        await fetchGames(); // Finally, fetch the games
      } catch (error) {
        console.error("Error setting up initial data:", error);
        setLoading(false); // Make sure to halt loading if there's an error
      }
    };

    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle form submission to Firestore
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(guesses); // For now, just log the guesses

    // Wait for the guesses to be saved, then go to the next screen
    await saveGuesses(getGoogleId(), guesses);

    toast.success("Answers saved!", {
      autoClose: 2000,
      hideProgressBar: true,
    });

    // Wait for the toast to finish, then go to the next screen
    setTimeout(() => {
      screenSelected("menu");
    }, 3000);
  };

  const handleGuessChange = (categoryId, gameId) => {
    setMadeEdits(true);
    setGuesses((prevGuesses) => ({ ...prevGuesses, [categoryId]: gameId }));
  };

  // On mouse enter set tooltip data
  const handleMouseEnter = (event, game) => {
    // Use game.id to fetch the game info
    setGameInfo(games[game.id]);
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <img
          src="https://cdn.thegameawards.com/frontend/gifs/TGA24_LOGO_LOADER.gif"
          alt="TGA-Logo"
          height="140"
          width="140"
        />
      </div>
    );
  }

  const { normalCategories, tiebreakerCategories, gotyCategory } = categories;
  const allCategories = [
    ...categories.normalCategories,
    ...categories.tiebreakerCategories,
    categories.gotyCategory,
  ];

  return (
    <>
      <ToastContainer />
      <div className={styles.layoutContainer}>
        <div className={styles.buttonBar}>
          <Button
            onClick={() => screenSelected("menu")}
            className={styles.backButton}
          >
            <ArrowBack />
          </Button>
          <Button
            type="submit"
            className={styles.submitButton}
            form="guessForm"
          >
            Save
          </Button>
        </div>
        <div className={styles.formColumn}>
          <form
            onSubmit={handleSubmit}
            className={styles.guessForm}
            id="guessForm"
          >
            {normalCategories.map((category) => (
              <div key={category.id}>
                <div
                  className={
                    category.id === "game-of-the-year"
                      ? styles.gameOfTheYearHeader
                      : styles.categoryTitleBox
                  }
                >
                  <h3>{category.name}</h3>
                </div>
                {category.nominations.map((game) => (
                  <label
                    key={game.id}
                    className={styles.radioButtonContainer}
                    // onMouseEnter={(event) => handleMouseEnter(event, game)}
                  >
                    <input
                      type="radio"
                      id={`nomination-${game.id}`}
                      name={`category-${category.id}`}
                      value={game.id}
                      checked={guesses[category.id] === game.id}
                      onChange={() => handleGuessChange(category.id, game.id)}
                      className={styles.hiddenRadio}
                    />
                    <span className={styles.customRadio}></span>
                    {game.title} &nbsp;
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="game-info"
                      onClick={(event) => handleMouseEnter(event, game)}
                    />
                  </label>
                ))}
              </div>
            ))}
            {tiebreakerCategories.length > 0 && (
              <>
                <div className={styles.tiebreakerHeader}>
                  <h2>Tiebreakers</h2>
                  <Tooltip
                    title="Tiebreakers are only used if there is a tie in the final results."
                    placement="right"
                  >
                    <IconButton>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </div>
                {tiebreakerCategories.map((category) => (
                  <div key={category.id}>
                    <div className={styles.categoryTitleBox}>
                      <h3>{category.name}</h3>
                    </div>
                    {category.nominations.map((game) => (
                      <label
                        key={game.id}
                        className={styles.radioButtonContainer}
                      >
                        <input
                          type="radio"
                          id={`nomination-${game.id}`}
                          name={`category-${category.id}`}
                          value={game.id}
                          checked={guesses[category.id] === game.id}
                          onChange={() =>
                            handleGuessChange(category.id, game.id)
                          }
                          className={styles.hiddenRadio}
                        />
                        <span className={styles.customRadio}></span>
                        {game.title} &nbsp;
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className="game-info"
                          onClick={(event) => handleMouseEnter(event, game)}
                        />
                      </label>
                    ))}
                  </div>
                ))}
              </>
            )}

            {gotyCategory && (
              <>
                <div className={styles.gameOfTheYearHeader}>
                  <h2>Game of the Year</h2>
                </div>
                {gotyCategory.nominations.map((game) => (
                  <label key={game.id} className={styles.radioButtonContainer}>
                    <input
                      type="radio"
                      id={`nomination-${game.id}`}
                      name={`category-${gotyCategory.id}`}
                      value={game.id}
                      checked={guesses[gotyCategory.id] === game.id}
                      onChange={() =>
                        handleGuessChange(gotyCategory.id, game.id)
                      }
                      className={styles.hiddenRadio}
                    />
                    <span className={styles.customRadio}></span>
                    {game.title} &nbsp;
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="game-info"
                      onClick={(event) => handleMouseEnter(event, game)}
                    />
                  </label>
                ))}
              </>
            )}
            <Button type="submit" className={styles.submitButton}>
              {" "}
              Save{" "}
            </Button>
          </form>
        </div>
      </div>
      <GameTooltip gameInfo={gameInfo} allCategories={allCategories} />
    </>
  );
};

export default GuessForm;
