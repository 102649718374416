import React from "react";
import { Button } from "@mui/material";
import styles from "../../styles/Rules/Rules.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import GameInfo from "../../images/rules/game_info.png";
import ToggleMenu from "../../images/rules/table_toggle.png";
import ReloadTable from "../../images/rules/reload.png";
import Announcements from "../../images/rules/announcements.png";
import Header from "../../images/rules/results_menu.png";

const Rules = () => {
  const back = () => {
    window.location.href = "/";
  };

  return (
    <>
      <div>
        <div className={styles.TitleBox}>
          <Button
            variant="outlined"
            onClick={back}
            className={styles.BackButton}
          >
            Back
          </Button>
          <h1 className={styles.Title}>Rules</h1>
        </div>
        <div className={styles.Rules}>
          <h3>Overview</h3>
          <p>
            Welcome to the 2024 TGA Leaderboard! Here you will be able to enter
            your guesses for the winners of each category for the 2024 TGA
            Awards.
          </p>
          <p>Five of the categories will be used to break ties:</p>
          <ul>
            <li>Adaptation</li>
            <li>Content Creator of the Year</li>
            <li>esports Game</li>
            <li>Mobile Game</li>
            <li>Most Anticipated Game</li>
          </ul>
          <p>
            Theses they will not be counted towards your total score. But if two
            people have the same score, the person who guessed the most of the
            five tiebreaker categories correctly will be the winner.
          </p>
          <h3>Submitting your Guesses</h3>
          <p>
            After you login, click the button at the bottom of the page labeled
            "Guesses". From there you will be able to enter your guesses for
            each category. Click on the info button{" "}
            <FontAwesomeIcon icon={faInfoCircle} /> to see some basic
            information about the game like, categories it is nominated for,
            metacritic score, and graphic of the gameplay.
          </p>
          <img src={GameInfo} alt="Game Info" />
          <h3>Results</h3>
          <img src={Header} alt="Results Menu" />
          <p>
            In Overview you will be able to see a table of your guesses. After
            guesses are closed you will be able to see the guesses of everyone.
            This table will also update when the winners are announced on the
            day of the TGA Awards. Clicking on the toggle button will show the
            different peoples guesses.
          </p>
          <img src={ToggleMenu} alt="Toggle Menu" />
          <p>
            You can also click on the table to show the game information. If you
            don't see the table update, you can click the reload button to
            update the table.
          </p>
          <img src={ReloadTable} alt="Reload Table" />
          <h3>Announcements</h3>
          <p>
            In the Announced tab you will be able to see the winners of each
            category. The winners will be shown in the order they were
            announced.
          </p>
          <img src={Announcements} alt="Announcements" />
        </div>
      </div>
    </>
  );
};

export default Rules;
