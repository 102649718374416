import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
  Stack,
} from "@mui/material";
import styles from "../../styles/Leaderboard.module.css";
import CreditsOverlay from "./CreditOverlay";

import Gold from "../../images/gold.png";
import Silver from "../../images/silver.png";
import Bronze from "../../images/bronze.png";
import Tiebreaker from "../../images/tiebreaker.png";

const Leaderboard = ({
  shouldReload,
  disableReload,
  participants,
  guesses,
  stats,
  winners,
}) => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [showCredits, setShowCredits] = useState(false);

  const tiebreakerCategories = useMemo(
    () => [
      "adaptation",
      "most-anticipated-game",
      "mobile-game",
      "content-creator-of-the-year",
      "esports-game",
    ],
    []
  );

  const participantsLookup = useMemo(() => {
    const lookup = {};
    participants.forEach((participant) => {
      lookup[participant.id] = participant;
    });
    return lookup;
  }, [participants]);

  const calculateLeaderboard = useCallback(() => {
    const newLeaderboard = Object.keys(guesses).map((participantId) => {
      const participantGuesses = guesses[participantId];
      let score = 0;
      let tiebreakers = 0;

      Object.keys(participantGuesses).forEach((categoryId) => {
        const participantGuess = participantGuesses[categoryId];
        const winner = winners.find((w) => w.categoryId === categoryId);
        if (winner && winner.gameId === participantGuess) {
          if (tiebreakerCategories.includes(winner.categoryId)) {
            tiebreakers += 1;
          } else {
            score += 1;
          }
        }
      });

      return {
        participantId,
        score,
        tiebreakers,
      };
    });

    // Sort leaderboard by score and then by tiebreakers in case of a tie
    newLeaderboard.sort(
      (a, b) => b.score - a.score || b.tiebreakers - a.tiebreakers
    );

    setLeaderboard(newLeaderboard);
  }, [guesses, tiebreakerCategories, winners]);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 400);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 400);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const gameOfTheYearAnnounced = winners.some(
      (winner) => winner.categoryId === "game-of-the-year"
    );
    if (gameOfTheYearAnnounced) {
      setShowCredits(true);
    }
  }, [winners]);

  const getInitials = (name) => {
    if (!name) {
      return "";
    }
    return name
      .split(" ")
      .map((word) => word[0])
      .join("");
  };

  const getDisplayName = (person) => {
    return isSmallScreen
      ? getInitials(participantsLookup[person.participantId]?.name)
      : participantsLookup[person.participantId]?.name || "";
  };

  useEffect(() => {
    if (leaderboard.length === 0) {
      calculateLeaderboard();
    }
  }, [leaderboard.length, calculateLeaderboard]);

  useEffect(() => {
    if (shouldReload) {
      calculateLeaderboard();
      disableReload();
    }
  }, [shouldReload, calculateLeaderboard, disableReload]);

  return (
    <>
      {showCredits && (
        <CreditsOverlay
          winners={winners}
          leaderboard={leaderboard}
          stats={stats}
          participants={participants}
          onClose={() => setShowCredits(false)}
        />
      )}
      <div className={styles.content}>
        <div className={styles.placements}>
          {[1, 0, 2].map((placementIndex) => {
            const person = leaderboard[placementIndex];
            return person ? (
              <div
                key={person.participantId}
                className={`${styles.placementItem} ${
                  styles[`place${placementIndex + 1}`]
                }`}
              >
                <img
                  className={
                    placementIndex === 1
                      ? styles.silver
                      : placementIndex === 0
                      ? styles.gold
                      : styles.bronze
                  }
                  src={
                    placementIndex === 1
                      ? Silver
                      : placementIndex === 0
                      ? Gold
                      : Bronze
                  }
                  alt="Placement"
                />
                <div className={styles.placementRank}>
                  <span className={styles.placementText}>
                    {placementIndex === 1
                      ? "2nd"
                      : placementIndex === 0
                      ? "1st"
                      : "3rd"}
                  </span>
                  <div className={styles.placementName}>
                    {getDisplayName(person)}
                  </div>
                </div>
              </div>
            ) : null;
          })}
        </div>
        <div className={styles.leaderboard}>
          <List className={styles.list}>
            <Stack spacing={2}>
              {leaderboard.map((person, index) => (
                <Paper
                  key={person.participantId}
                  className={styles.participant}
                  elevation={index < 3 ? 3 - index : 0}
                >
                  <ListItem>
                    <ListItemIcon>
                      <div className={styles.participantSmall}>
                        <img
                          className={styles.participantImage}
                          src={
                            participantsLookup[person.participantId]?.picture
                          }
                          alt={participantsLookup[person.participantId]?.name}
                        />
                        <ListItemText
                          primary={
                            participantsLookup[
                              person.participantId
                            ]?.name.split(" ")[0]
                          }
                          className={styles.participantNameSmall}
                        />
                      </div>
                    </ListItemIcon>
                    <ListItemText
                      primary={participantsLookup[person.participantId]?.name}
                      className={styles.participantName}
                    />

                    <div className={styles.tiebreakerImages}>
                      <ListItemIcon>
                        {person.tiebreakers > 0 &&
                          Array.from({ length: person.tiebreakers }).map(
                            (_, index) => (
                              <img
                                key={index}
                                className={styles.tiebreakerImage}
                                src={Tiebreaker}
                                alt="Tiebreaker"
                              />
                            )
                          )}
                      </ListItemIcon>
                    </div>
                    <div className={styles.score}>{person.score}</div>
                  </ListItem>
                </Paper>
              ))}
            </Stack>
          </List>
        </div>
      </div>
    </>
  );
};

export default Leaderboard;
